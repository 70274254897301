import { Cookie } from '@common-vue/util/src/modules/cookie';

export const generateLogin365FingerPrint = async (PersistentLoginUtil = {}, rootState = {}) => {
  const { Experimentation } = require('@vue-util/experimentation');
  const isPersistentLoginExperimentEnabledBcom = await Experimentation.isRecipeSelected('2065-21');
  let persistentLoginEnabledKS = rootState.killswitches?.persistentLoginKSEnabled;
  const isMcom = rootState.envProps.entryPoint?.toUpperCase() === 'MCOM';
  if (!isMcom) {
    persistentLoginEnabledKS = rootState.killswitches?.persistentLoginKSEnabled && isPersistentLoginExperimentEnabledBcom;
  }
  if (persistentLoginEnabledKS) {
    const experimentIds = Experimentation.getExpIds().toString();
    const isLogin365Customer = (Cookie.get('Login365Customer', 'MISCGCs') === 'Y') || false;
    const persistentLoginHeaders = {
      'X-Macys-Experiment-Ids': experimentIds,
      'X-Macys-Login365Customer': isLogin365Customer,
    };
    return Object.assign(PersistentLoginUtil.getDeviceFingerPrintHeaders(), persistentLoginHeaders);
  }
  return {};
};

export default {
  generateLogin365FingerPrint,
};
